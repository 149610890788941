<template>
  <div class="pt-4">
      <span :class="this.$store.state.online ? 'status green--text' : 'status red--text'">{{this.$store.state.online ? 'Connected to LPS' : 'Disconnected from LPS' }}</span>

      <div class="container" v-if="!this.$store.state.online">
        <v-text-field outlined dense block v-model="options.endpoint" type="text" placeholder="URL (eg. http://127.0.0.1:5000/centralHub)"></v-text-field>
        <v-btn color="blue" class="white--text lsn" @click="connectToLPS">Connect</v-btn>
      </div>
      <div v-else>

      <v-row>
        <v-col cols="6">
        <div class="container">
          <v-text-field v-model="action" type="text" placeholder="Action" outlined />
          <v-text-field type="text" :value="this.$store.state.lastMessageUuid" disabled outlined />
          <v-textarea v-model="json" placeholder="JSON">
          </v-textarea>
          <v-btn class="lsn" color="secondary" @click="sendMessage">Submit!</v-btn>
        </div>
        </v-col>
      <v-col cols="6">
        <vue-json-pretty v-bind:key="index" v-for="(message, index) in this.$store.state.messages"
                         :data="message"></vue-json-pretty>
      </v-col>
      </v-row>
      </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';

import runner from "../connection";

export default {
  name: 'Dashboard',
  components: {
    VueJsonPretty
  },
  data: () => ({
    action: "",
    json: "",
    options: {
      endpoint: "http://127.0.0.1:5000/centralHub"
    }
  }),
  methods: {
    connectToLPS() {
      this.$store.commit('setBaseQueryParams', this.options);
      if (this.$route.query.appRunUuid) {
        this.$store.commit('setAppRunUuid', this.$route.query.appRunUuid);
      }
      runner.initializeRunnerConnection(this.$store.getters.baseQueryParams, this.$store).then(() => {
        if (this.$store.state.appRunUuid) {
          this.startInterval()
        }
      })
    },
    sendHeartbeat() {
      this.$store.dispatch("sendMessageToRunner", {
        message: {
          action: "System.DisplayManager.ApplicationHeartbeat",
          data: {
            appRunUuid: this.$store.state.appRunUuid,
            timeout: 20
          }
        }
      })
    },
    startInterval() {
      this.sendHeartbeat();
      setInterval(() => {
        this.sendHeartbeat();
      }, 20000)
    },
    sendMessage() {
      this.$store.dispatch("sendMessageToRunner", {
        message: {
          action: this.action,
          data: this.json ? JSON.parse(this.json) : "{}"
        }
      }).then(response => {
        console.log(response);
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
